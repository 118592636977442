import awsmobile from './amplifyconfiguration.json';

export const awsConfig = {
    awsmobile: {
        ...awsmobile,
        Analytics: {
            ...(awsmobile.Analytics ?? {}),
            KinesisFirehose: {
                region: awsmobile.aws_project_region
            }
        }
    },
    analytics: {
        firehoseStreamName: 'race-planner-analytics'
    },
    restApi: {
        name: 'RacePlannerEmailAPI'
    }
};

export const awsAnalyticsConfig = {
    Analytics: awsConfig.awsmobile.Analytics
};
