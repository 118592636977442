export const ScreenName = {
    home: 'home',
    termsAndConditions: 'terms_and_conditions',
    privacyPolicy: 'privacy_policy',
    contact: 'contact',
    requestDeleteAccount: 'request_delete_account',
    runPaceCalculator: 'run_pace_calculator',
    swimPaceCalculator: 'swim_pace_calculator',
    speedCalculator: 'speed_calculator',
    unitsConverter: 'units_converter',
    runWalkTimeCalculator: 'run_walk_time_calculator',
    runWalkDistanceCalculator: 'run_walk_distance_calculator',
    runWalkRunningPaceCalculator: 'run_walk_running_pace_calculator',
    runWalkWalkingPaceCalculator: 'run_walk_walking_pace_calculator',
    runWalkRunningIntervalCalculator: 'run_walk_running_interval_calculator',
    runWalkWalkingIntervalCalculator: 'run_walk_walking_interval_calculator'
};
