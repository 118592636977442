import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@race-planner-web/app-screen-skeleton';

const AsyncUnitsConverter = lazyForPaint(
    () => import(/* webpackChunkName: "async-units-converter" */ './index')
        .then(({ UnitsConverter }) => UnitsConverter)
);

export const UnitsConverter = () => {
    di(AsyncUnitsConverter, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncUnitsConverter />
        </LazySuspense>
    );
};
