import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@race-planner-web/app-screen-skeleton';

const AsyncSpeedCalculator = lazyForPaint(
    () => import(/* webpackChunkName: "async-speed-calculator" */ './index')
        .then(({ SpeedCalculator }) => SpeedCalculator)
);

export const SpeedCalculator = () => {
    di(AsyncSpeedCalculator, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncSpeedCalculator />
        </LazySuspense>
    );
};
