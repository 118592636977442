import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@race-planner-web/app-screen-skeleton';

const AsyncHome = lazyForPaint(
    () => import(/* webpackChunkName: "async-home" */ './index')
        .then(({ Home }) => Home)
);

export const Home = () => {
    di(AsyncHome, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncHome />
        </LazySuspense>
    );
};
