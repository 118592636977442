export const firebaseConfig = {
    apiKey: 'AIzaSyBDD1nJp-3FSRlS7I0WbIR8b1X1RUff2b0',
    authDomain: 'run-app-f6d4d.firebaseapp.com',
    databaseURL: 'https://run-app-f6d4d.firebaseio.com',
    projectId: 'run-app-f6d4d',
    storageBucket: 'run-app-f6d4d.appspot.com',
    messagingSenderId: '335806058719',
    appId: '1:335806058719:web:353675074e8241ddc1d96a',
    measurementId: 'G-VPJM1TVBC1'
};
