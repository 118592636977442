import styled from 'styled-components';
import { commonContentPositionStyle } from '@race-planner-web/app-page';

export const ContentContainer = styled.div`
    ${commonContentPositionStyle};
    display: flex;
    flex: 1 0 auto;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme: { colors: { background } } }) => background};
`;
