import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import forEach from 'lodash/forEach';
import './index.css';
import { App } from '@race-planner-web/app';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Amplify } from 'aws-amplify';
import {
    awsConfig,
    awsAnalyticsConfig
} from '@race-planner/aws-amplify/src/aws.config';
import { useAppInfoActions, APP_CONTAINER } from '@codexporer.io/expo-app-info';
import { initialize as initializeRequestCountry } from '@codexporer.io/request-country';
import { initialize as initializeRequestIp } from '@codexporer.io/request-ip';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAppAnalytics, EventName } from '@race-planner-web/app-analytics-bridge';
import reportWebVitals from './reportWebVitals';
import { firebaseConfig } from './firebase.config';

initializeIcons();

forEach([
    initializeRequestCountry,
    initializeRequestIp
], initialize => {
    initialize({
        saveData: (key, data) => AsyncStorage.setItem(key, data),
        loadData: key => AsyncStorage.getItem(key),
        retentionInMs: 60 * 60 * 24 * 1000 // 1 day
    });
});

const AppRoot = () => {
    const [isInitializationStarted, setIsInitializationStarted] = useState(false);
    const [
        areIndependantPrerequisitesLoaded,
        setAreIndependantPrerequisitesLoaded
    ] = useState(false);
    const [arePrerequisitesLoaded, setArePrerequisitesLoaded] = useState(false);
    const [, { initialize: initializeAppInfo }] = useAppInfoActions();
    const {
        initialize: initializeAnalytics,
        sendEvent
    } = useAppAnalytics({ firebaseConfig, awsConfig });

    // Independent prerequisites
    useEffect(() => {
        if (!isInitializationStarted) {
            setIsInitializationStarted(true);
            Promise.all([
                initializeAppInfo({ appContainer: APP_CONTAINER.web })
            ]).then(() => setAreIndependantPrerequisitesLoaded(true));
        }
    }, [
        isInitializationStarted,
        setIsInitializationStarted,
        setAreIndependantPrerequisitesLoaded,
        initializeAppInfo
    ]);

    // Analytics, and other dependent prerequisites
    useEffect(() => {
        const canLoadPrerequisites = areIndependantPrerequisitesLoaded && !arePrerequisitesLoaded;
        if (canLoadPrerequisites) {
            initializeAnalytics();
            // Must to be called after initializeAnalytics
            Amplify.configure(awsConfig.awsmobile);
            Amplify.configure({
                ...Amplify.getConfig(),
                ...awsAnalyticsConfig
            });
            setArePrerequisitesLoaded(true);
        }
    }, [
        areIndependantPrerequisitesLoaded,
        arePrerequisitesLoaded,
        initializeAnalytics,
        setArePrerequisitesLoaded
    ]);

    useEffect(() => {
        if (arePrerequisitesLoaded) {
            sendEvent({ name: EventName.appStart });
        }
    }, [arePrerequisitesLoaded, sendEvent]);

    if (!arePrerequisitesLoaded) {
        return null;
    }

    return <App />;
};

ReactDOM.render(
    <React.StrictMode>
        <AppRoot />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
