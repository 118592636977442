import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@race-planner-web/app-screen-skeleton';

const AsyncRunWalkCalculator = lazyForPaint(
    () => import(/* webpackChunkName: "async-run-walk-calculator" */ './index')
        .then(({ RunWalkCalculator }) => RunWalkCalculator)
);

export const RunWalkCalculator = () => {
    di(AsyncRunWalkCalculator, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncRunWalkCalculator />
        </LazySuspense>
    );
};
