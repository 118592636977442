import React from 'react';
import { di } from 'react-magnetic-di';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as FluentUiThemeProvider, createTheme } from '@fluentui/react';

const fluentUiTheme = createTheme({
    palette: {
        themePrimary: '#6200ee',
        themeLighterAlt: '#f8f4fe',
        themeLighter: '#e5d4fc',
        themeLight: '#ceaffa',
        themeTertiary: '#9f62f4',
        themeSecondary: '#741def',
        themeDarkAlt: '#5900d5',
        themeDark: '#4b00b4',
        themeDarker: '#370085',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#ededed',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff'
    }
});

const styledTheme = {
    colors: {
        primary: fluentUiTheme.palette.themePrimary,
        primaryAlt: fluentUiTheme.palette.themeLighterAlt,
        primaryAltHover: fluentUiTheme.palette.themeLighter,
        background: fluentUiTheme.palette.white,
        text: fluentUiTheme.palette.neutralPrimary,
        warning: '#cfac00',
        error: '#d40000'
    },
    style: {
        imgBlackToPrimaryFilter: 'invert(15%) sepia(85%) saturate(7027%) hue-rotate(267deg) brightness(84%) contrast(126%)',
        imgBlackToWhiteFilter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(14deg) brightness(101%) contrast(101%)'
    },
    fonts: {
        tiny: fluentUiTheme.fonts.tiny,
        xSmall: fluentUiTheme.fonts.xSmall,
        small: fluentUiTheme.fonts.small,
        smallPlus: fluentUiTheme.fonts.smallPlus,
        medium: fluentUiTheme.fonts.medium,
        mediumPlus: fluentUiTheme.fonts.mediumPlus,
        large: fluentUiTheme.fonts.large,
        xLarge: fluentUiTheme.fonts.xLarge,
        xLargePlus: fluentUiTheme.fonts.xLargePlus,
        xxLarge: fluentUiTheme.fonts.xxLarge,
        xxLargePlus: fluentUiTheme.fonts.xxLargePlus,
        superLarge: fluentUiTheme.fonts.superLarge,
        mega: fluentUiTheme.fonts.mega
    }
};

export const AppThemeProvider = ({ children }) => {
    di(FluentUiThemeProvider, StyledThemeProvider);

    return (
        <StyledThemeProvider theme={styledTheme}>
            <FluentUiThemeProvider theme={fluentUiTheme}>
                {children}
            </FluentUiThemeProvider>
        </StyledThemeProvider>
    );
};
