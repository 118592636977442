import React from 'react';
import { Home } from '@race-planner-web/home-screen/src/async';
import { TermsAndConditions } from '@race-planner-web/toc-screen/src/async';
import { PrivacyPolicy } from '@race-planner-web/privacy-policy-screen/src/async';
import { RequestDeleteAccount } from '@race-planner-web/request-delete-account-screen/src/async';
import { Contact } from '@race-planner-web/contact-screen/src/async';
import { PaceCalculator } from '@race-planner-web/pace-calculator-screen/src/async';
import { SpeedCalculator } from '@race-planner-web/speed-calculator-screen/src/async';
import { UnitsConverter } from '@race-planner-web/units-converter-screen/src/async';
import { SubscriptionPlans } from '@race-planner-web/subscription-plans-screen/src/async';
import { RunWalkCalculator } from '@race-planner-web/run-walk-calculator-screen/src/async';
import { Seo } from '@race-planner-web/seo';
import { di } from 'react-magnetic-di';

const getAppRoutes = ({
    Home,
    TermsAndConditions,
    PrivacyPolicy,
    Contact,
    PaceCalculator,
    RequestDeleteAccount,
    SpeedCalculator,
    UnitsConverter,
    RunWalkCalculator,
    SubscriptionPlans,
    Seo
}) => [
    {
        name: 'terms-and-conditions',
        path: '/terms-and-conditions',
        exact: true,
        component: () => (
            <>
                <Seo title='Terms & Conditions' />
                <TermsAndConditions />
            </>
        ),
        resources: []
    },
    {
        name: 'privacy-policy',
        path: '/privacy-policy',
        exact: true,
        component: () => (
            <>
                <Seo title='Privacy' />
                <PrivacyPolicy />
            </>
        ),
        resources: []
    },
    {
        name: 'request-delete-account',
        path: '/request-delete-account',
        exact: true,
        component: () => (
            <>
                <Seo title='Request Account Removal' />
                <RequestDeleteAccount />
            </>
        ),
        resources: []
    },
    {
        name: 'subscription-plans',
        path: '/subscription-plans',
        exact: true,
        component: () => (
            <>
                <Seo title='Subscriptions' />
                <SubscriptionPlans />
            </>
        ),
        resources: []
    },
    {
        name: 'contact',
        path: '/contact',
        exact: true,
        component: () => (
            <>
                <Seo title='Contact' />
                <Contact />
            </>
        ),
        resources: []
    },
    {
        name: 'pace-calculator',
        path: '/pace-calculator',
        exact: true,
        component: () => (
            <>
                <Seo
                    title='Pace Calculator'
                    keywords={[
                        'pace calculator',
                        'swim calculator',
                        'run calculator',
                        'triathlon calculator',
                        'calculate'
                    ]}
                />
                <PaceCalculator />
            </>
        ),
        resources: []
    },
    {
        name: 'speed-calculator',
        path: '/speed-calculator',
        exact: true,
        component: () => (
            <>
                <Seo
                    title='Speed Calculator'
                    keywords={[
                        'speed calculator',
                        'bike calculator',
                        'triathlon calculator',
                        'calculate'
                    ]}
                />
                <SpeedCalculator />
            </>
        ),
        resources: []
    },
    {
        name: 'run-walk-calculator',
        path: '/run-walk-calculator',
        exact: true,
        component: () => (
            <>
                <Seo
                    title='Run walk calculator'
                    keywords={[
                        'pace calculator',
                        'run calculator',
                        'walk calculator',
                        'run',
                        'walk',
                        'calculate'
                    ]}
                />
                <RunWalkCalculator />
            </>
        ),
        resources: []
    },
    {
        name: 'units-converter',
        path: '/units-converter',
        exact: true,
        component: () => (
            <>
                <Seo
                    title='Units Converter'
                    keywords={[
                        'units converter',
                        'converter'
                    ]}
                />
                <UnitsConverter />
            </>
        ),
        resources: []
    },
    // If route is not found, redirect to home
    {
        name: 'home',
        path: '/',
        component: () => (
            <>
                <Seo title='Home' />
                <Home />
            </>
        ),
        resources: []
    }
];

export const useAppRoutes = () => {
    di(
        Contact,
        Home,
        PaceCalculator,
        PrivacyPolicy,
        RequestDeleteAccount,
        RunWalkCalculator,
        Seo,
        SpeedCalculator,
        SubscriptionPlans,
        TermsAndConditions,
        UnitsConverter
    );

    return getAppRoutes({
        Home,
        TermsAndConditions,
        PrivacyPolicy,
        Contact,
        PaceCalculator,
        RequestDeleteAccount,
        SpeedCalculator,
        UnitsConverter,
        RunWalkCalculator,
        SubscriptionPlans,
        Seo
    });
};
