import styled, { css } from 'styled-components';
import { Link } from 'react-resource-router';
import { forLargeDevices, forExtraSmallDevices } from '@race-planner-web/app-theme';

export const commonContentPositionStyle = css`
    width: 100%;
    max-width: 1000px;
    position: relative;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;

export const ScreenRoot = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

export const Screen = styled.div`
    background-color: ${({ theme: { colors: { background } } }) => background};
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;

    ${forLargeDevices('width: 100%;')}
`;

export const Header = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme: { colors: { primary } } }) => primary};
    min-height: 40px;
    color: ${({ theme: { colors: { primaryAlt } } }) => primaryAlt};
    text-decoration: none;
    box-shadow: ${({ hasBorderBottom }) => hasBorderBottom ? '0 0 2px black' : 'none'};
    z-index: 1;
    padding-left: 10px;

    ${forExtraSmallDevices('justify-content: center;')}
`;

export const HeaderLogo = styled.img`
    height: 25px;
`;

export const HeaderText = styled.div`
    font-size: 18px;
    margin-left: 10px;
    font-weight: 600;
`;

export const Content = styled.div`
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    background-color: ${({ theme: { colors: { primary } } }) => primary};
    color: ${({ theme: { colors: { primaryAlt } } }) => primaryAlt};
`;

export const Footer = styled.div`
    ${commonContentPositionStyle};
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 380px;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;

  ${({ isFlex }) => forExtraSmallDevices(
        isFlex && 'flex-direction: column;'
    )}
`;

export const FooterColumn = styled.div`
    &:not(:first-child) {
        margin-left: 100px;
    }

    ${({ isFlex }) => forExtraSmallDevices(
        isFlex && `
            &:not(:first-child) {
                margin-top: 20px;
                margin-left: 0;
            }
        `
    )}
`;

export const NavLink = styled(Link)`
    text-decoration: none;
    white-space:pre;
    color: inherit;
    &:hover {
        color: ${({ theme: { colors: { primaryAltHover } } }) => primaryAltHover};
    }
`;

export const Subtitle = styled.div`
    text-decoration: underline;
    font-weight: 600;
`;

export const Copyright = styled.div`
    text-decoration: none;
    margin-top: 40px;
`;

export const FooterSeparator = styled.div`
    background-color: white;
    height: 2px;
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 300px;
`;

export const Title = styled.div`
    font-size: 40px;
`;
