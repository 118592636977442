import React from 'react';
import { di } from 'react-magnetic-di';
import {
    Router,
    RouteComponent,
    createBrowserHistory
} from 'react-resource-router';
import { useAppRoutes } from '@race-planner-web/app-routes';
import { AppThemeProvider } from '@race-planner-web/app-theme';
import { MessageDialog } from '@codexporer.io/fluent-ui-message-dialog';

export const App = () => {
    di(AppThemeProvider, MessageDialog, RouteComponent, Router, createBrowserHistory, useAppRoutes);

    const appRoutes = useAppRoutes();

    return (
        <AppThemeProvider>
            <MessageDialog />
            <Router routes={appRoutes} history={createBrowserHistory()}>
                <RouteComponent />
            </Router>
        </AppThemeProvider>
    );
};
