import React from 'react';
import Helmet from 'react-helmet';
import uniq from 'lodash/uniq';

export const Seo = ({
    description = 'Race Planner will help you to organize your races, give you an overview of the completed races and past performances, and help you with planning your next PR.',
    lang = 'en',
    meta = [],
    keywords = [],
    title,
    image
}) => {
    const metaKeywords = uniq([
        ...(['run', 'swim', 'bike', 'triathlon', 'race', 'planner']),
        ...keywords
    ]);

    const author = 'codexplorer.io';

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate='%s | Race Planner'
            meta={[
                {
                    name: 'description',
                    content: description
                },
                ...(
                    metaKeywords.length > 0 ?
                        [{
                            name: 'keywords',
                            content: metaKeywords.join(', ')
                        }] :
                        []
                ),
                {
                    property: 'author',
                    content: author
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: description
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                ...(
                    image ?
                        [{
                            property: 'og:image',
                            content: image
                        }] :
                        []
                ),
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: description
                },
                ...meta
            ]}
        />
    );
};
