export const EventName = {
    appStart: 'app_start',
    playStoreButtonClicked: 'play_store_button_clicked',
    appStoreButtonClicked: 'app_store_button_clicked',
    submitContactButtonClicked: 'submit_contact_button_clicked',
    calculateDistance: 'calculate_distance',
    calculateTime: 'calculate_time',
    calculateSpeed: 'calculate_speed',
    calculatePace: 'calculate_pace',
    calculateInterval: 'calculate_interval',
    convertValue: 'convert_value',
    openUnexpectedInputErrorDialog: 'open_unexpected_input_error_dialog'
};
