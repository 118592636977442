import { useCallback, useRef } from 'react';
import { di } from 'react-magnetic-di';
import { requestCountry } from '@codexporer.io/request-country';
import { requestIp } from '@codexporer.io/request-ip';
import { useAppInfo } from '@codexporer.io/expo-app-info';
import {
    initialize as initializeAnalytics,
    sendEvent,
    sendScreenEvent
} from '@codexporer.io/app-analytics';

export const useAppAnalytics = ({ firebaseConfig, awsConfig } = {}) => {
    di(
        Date,
        initializeAnalytics,
        requestCountry,
        requestIp,
        sendEvent,
        sendScreenEvent,
        useAppInfo
    );

    const [{
        appName,
        appVersion,
        sessionId,
        installationId: clientId,
        appContainer
    }] = useAppInfo();
    const dependenciesRef = useRef({});

    const getAttributes = async ({ attributes }) => {
        const country = await requestCountry();
        const ip = await requestIp();
        return {
            ...(attributes ?? {}),
            app_version: appVersion,
            ...(
                appContainer ?
                    { app_container: appContainer } :
                    {}
            ),
            client_id: clientId,
            session_id: sessionId,
            utc_timestamp: new Date().toISOString(),
            ...(
                country ?
                    {
                        country_code: country.isoCode,
                        country_name: country.name
                    } :
                    {}
            ),
            ...(ip ? { ip_address: ip } : {}),
            is_localhost: window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
        };
    };

    dependenciesRef.current = {
        appName,
        appVersion,
        awsConfig,
        clientId,
        firebaseConfig,
        sessionId,
        getAttributes
    };

    const initializeFn = useCallback(() => {
        const {
            appName,
            appVersion,
            awsConfig: { analytics },
            clientId,
            firebaseConfig,
            sessionId
        } = dependenciesRef.current;
        initializeAnalytics({
            firebase: {
                config: firebaseConfig,
                options: {
                    clientId,
                    sessionId,
                    appName,
                    appVersion
                }
            },
            amplify: analytics
        });
    }, []);

    const sendEventFn = useCallback(async ({ name, attributes }) => {
        const { getAttributes } = dependenciesRef.current;
        attributes = await getAttributes({ attributes });
        sendEvent({ name, attributes });
    }, []);

    const sendScreenEventFn = useCallback(async ({ screenName, attributes }) => {
        const { getAttributes } = dependenciesRef.current;
        attributes = await getAttributes({ attributes });
        sendScreenEvent({ screenName, attributes });
    }, []);

    return {
        initialize: initializeFn,
        sendEvent: sendEventFn,
        sendScreenEvent: sendScreenEventFn
    };
};
