import React, { useState } from 'react';
import { di } from 'react-magnetic-di';
import logo from './assets/logo.svg';
import {
    ScreenRoot,
    Screen,
    Header,
    Content,
    FooterContainer,
    Footer,
    FooterRow,
    FooterColumn,
    NavLink,
    Copyright,
    FooterSeparator,
    Title,
    Subtitle,
    commonContentPositionStyle,
    HeaderLogo,
    HeaderText
} from './styled';

export const AppPage = ({ children }) => {
    di(
        Content,
        Copyright,
        Footer,
        FooterColumn,
        FooterContainer,
        FooterRow,
        FooterSeparator,
        Header,
        HeaderLogo,
        HeaderText,
        NavLink,
        Screen,
        ScreenRoot,
        Subtitle,
        Title,
        useState
    );

    const [isContentScrolled, setIsContentScrolled] = useState(false);

    const handleContentScroll = event => {
        setIsContentScrolled(event.target.scrollTop > 5);
    };

    return (
        <ScreenRoot>
            <Screen>
                <Header
                    to='/'
                    hasBorderBottom={isContentScrolled}
                >
                    <HeaderLogo src={logo} />
                    <HeaderText>Race Planner</HeaderText>
                </Header>
                <Content onScroll={handleContentScroll}>
                    {children}
                    <FooterContainer>
                        <Footer>
                            <FooterRow>
                                <FooterColumn>
                                    <Title>Race Planner</Title>
                                    <FooterSeparator />
                                </FooterColumn>
                            </FooterRow>
                            <FooterRow isFlex>
                                <FooterColumn isFlex>
                                    <NavLink to='/#'>Home</NavLink>
                                    <br />
                                    <NavLink to='/terms-and-conditions'>Terms & conditions</NavLink>
                                    <br />
                                    <NavLink to='/privacy-policy'>Privacy policy</NavLink>
                                    <br />
                                    <NavLink to='/contact'>Contact us</NavLink>
                                </FooterColumn>
                                <FooterColumn isFlex>
                                    <Subtitle>Tools</Subtitle>
                                    <NavLink to='/pace-calculator'>Pace calculator</NavLink>
                                    <br />
                                    <NavLink to='/speed-calculator'>Speed calculator</NavLink>
                                    <br />
                                    <NavLink to='/run-walk-calculator'>Run walk calculator</NavLink>
                                    <br />
                                    <NavLink to='/units-converter'>Units converter</NavLink>
                                </FooterColumn>
                            </FooterRow>
                            <FooterRow>
                                <FooterColumn>
                                    <Copyright>Copyright © 2023 Race Planner</Copyright>
                                </FooterColumn>
                            </FooterRow>
                        </Footer>
                    </FooterContainer>
                </Content>
            </Screen>
        </ScreenRoot>
    );
};

export { commonContentPositionStyle };
